import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import LogoTitle from '../../assets/images/logo-d.png'
import './index.scss'
import Spline from '@splinetool/react-spline';

const Home = () => {
    const [letterClass, setLetterClass] = useState('text-animate')

    const nameArray = [..."aljeet (Danny),"]
    const jobArray = [..."Software developer."]

    useEffect(() => {

        let timeoutId = setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 4000)

        return () => {
            clearTimeout(timeoutId)
        }
    }, [])

    return (
        <>
            <div className="container home-page">
                <div className="text-zone">
                    <h1>
                        <span className={letterClass}>H</span>
                        <span className={`${letterClass} _12`}>i,</span>
                        <br />
                        <span className={`${letterClass} _13`}>I</span>
                        <span className={`${letterClass} _14`}>'m</span>
                        <img
                            src={LogoTitle}
                            alt="D alphabet"
                        />
                        <AnimatedLetters
                            letterClass={letterClass}
                            strArray={nameArray}
                            idx={15}
                        />
                        <br />
                        <AnimatedLetters
                            letterClass={letterClass}
                            strArray={jobArray}
                            idx={17}
                        />
                    </h1>
                    <h2>Full Stack Web / DevOps / Cloud</h2>
                    <Link to="/contact" className="flat-button">
                        CONTACT ME
                    </Link>
                </div>
                {/* <Logo /> */}
                <div className="spline-container">
                    <Spline scene="https://prod.spline.design/fIAm25womDxV1aWA/scene.splinecode" />
                </div>
            </div>

            <Loader type="pacman" />
        </>
    )
}

export default Home
