import { useEffect, useState } from 'react'
import {
  faAws,
  faGitAlt,
  faJava,
  faJsSquare,
  faPython,
} from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.scss'
import { faDatabase } from '@fortawesome/free-solid-svg-icons'

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={[..."About me"]}
              idx={15}
            />
          </h1>
          <p>
            I'm a very ambitious software developer who build and maintain financial
            products that serve millions of customers across North America. I primarily
            work with Full stack web, DevOps, and Cloud Computing. I am also familiar with
            Blockchain through Ethereum and Cosmos. Most importantly, I am always open to
            learning new tools and technologies that are essential in creating business value
            for companies and assisting customers, especially with fintech.
          </p>
          <p align="LEFT">
            I enjoy cooking Mediterranean food, learning new languages (currently proficient in four),
            playing instruments (guitar and piano), reading non-fiction books and working on open-source
            and personal projects in my spare time.
          </p>
          <p>
            Please feel free to contact me via the LinkedIn,
            contact form present on my website, or directly through my email.
            I am always looking forward to new opportunities and insightful conversations over coffee.
          </p>
          <p>
            <i>Note: You can download my resume by clicking on the green file icon in the sidebar</i>
          </p>
        </div>

        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
              <FontAwesomeIcon icon={faJava} color="#ED1D25" />
            </div>
            <div className="face2">
              <FontAwesomeIcon icon={faAws} color="#FF9900" />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faPython} color="#4584b6" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faDatabase} color="#5ED4F4" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
            </div>
            <div className="face6">
              <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
            </div>
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default About
